$color: #ee3325;
.outline {
  padding: 13px 24px !important;
  gap: 8px !important;
  border: 2px solid #283153 !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: #283153 !important;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .1rem;

  &:hover {
    color: white !important;
    background-color: #283153 !important;
  }

  &:active {
    outline: none !important;
  }
}

.regular {
  padding: 13px 24px !important;
  gap: 8px !important;
  border: 2px solid #283153 !important;
  border-radius: 4px !important;
  background-color: #283153 !important;
  color: #FFFFFF !important;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .1rem;

  &:hover {
    background-color: #283153 !important;
    color: white !important;
  }
}