$color: #ee3325;
/* stying class modal-content hidden in Modal component */
.modalBorder>div {
  border-radius: 20px !important;
}

.viewMap {
  width: 100%;
  height: 100%;
  padding: 0 32px;
}

.store {
  cursor: pointer;
  width: 120px;
}

@media only screen and (max-width: 400px) {
  .viewMap {
    padding: 0px;
  }
}

.footer {
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .footer {
    padding-bottom: 50px;
  }
}

.footerbg {
  // background: linear-gradient(180deg, #283153 0%, #2A4D7D 100%, rgba(217, 217, 217, 0) 100%);
  background-color: #4C505D;
}

.footerLink {
  cursor: pointer;
}

.title {
  font-weight: 700 !important;
  font-size: 50px !important;
}

.subtitle {
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 44px;
  margin: 0;
}

.negativeImage {
  -webkit-filter: invert(1);
  filter: brightness(0) invert(1);
}

.hero {
  width: 100%;
  // min-height: calc(100vh - 180px);
  // min-height: 100vh;

  background-color: #20222e;

  &__content {
    position: relative;
    z-index: 1000;
  }
}

.hfull {
  min-height: calc(100vh - 200px);
}

.bannerPhone {
  width: 600px;
  margin-bottom: 20px;
}

.title {
  font-weight: 700 !important;
  font-size: 45px !important;
}

.subtitles {
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 44px;
  margin: 0;
}

.qr {
  width: 150px;
}

.dividerVertical {
  border-left: 2px solid #FFFFFF;
  height: 150px;
  margin: 0 30px;
}

.carousel {
  width: 75%;
}

.hr {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
  line-height: 0.1em;
  margin: 1rem 0 3rem;
}

.hr span {
  background: #20222e;
  padding: 0 1.5em;
  color: #fff
}

@media screen and (max-width: 320px) {
  .subtitle {
    font-size: 18px !important;
  }

  .title {
    font-size: 25px !important;
  }

  .subtitles {
    font-size: 18px !important;
    line-height: 30px;
  }

  .qr {
    width: 80px;
  }

  .hero {
    min-height: 100vh !important;
  }
}

@media screen and (max-width: 480px) {
  .subtitle {
    font-size: 20px !important;
  }

  .bannerPhone {
    width: 350px !important;
    margin-bottom: 20px;
  }

  .title {
    font-size: 35px !important;
  }

  .subtitles {
    font-size: 20px !important;
    line-height: 30px;
  }

  .qr {
    width: 120px;
  }

  .hero {
    min-height: 100vh !important;
  }
}

@media screen and (max-width: 575px) {
  .dividerVertical {
    border-left: 150px solid #FFFFFF;
    height: 2px;
    margin: 10px 0;
  }

  .carousel {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .bannerPhone {
    width: 400px !important;
  }

  .title {
    font-size: 40px !important;
  }

  .subtitles {
    font-size: 20px !important;
    line-height: 30px;
  }

  .qr {
    width: 150px;
  }

  .hero {
    min-height: 100vh !important;
  }
}

@media screen and (max-width: 810px) {
  .bannerPhone {
    width: 550px !important;
  }

  .qr {
    width: 150px;
  }

  .store {
    width: 100px;
  }

  .hero {
    min-height: 70vh;
  }
}

@media screen and (max-width: 1024px) {
  .bannerPhone {
    width: 360px !important;
  }

  .title {
    font-size: 35px !important;
    margin-bottom: 0;
  }

  .subtitles {
    font-size: 15px !important;
  }

  .qr {
    width: 120px;
  }

  .store {
    width: 100px;
  }

  .hero {
    min-height: 100vh;
  }
}